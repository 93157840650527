module.exports = {
  presence: 'Obecność',
  presences: {
    others: 'Inne',
    onTime: 'Pacjent był na czas',
    patientCancelledPayable: 'Pacjent odwołał - sesja płatna',
    patientCancelledNotPayable: 'Pacjent odwołał - sesja niepłatna',
    meetingCancelledByMe: 'Sesja odwołana',
    meetingDidNotHappen: 'Spotkanie nie odbyło się',
    didNotHappen: 'Nie odbyło się',
    holiday: 'Urlop/Święto',
    outsideWorkingHours: 'Poza godzinami pracy'
  },
  groupPresences: {
    meetingTookPlace: 'Sesja odbyła się',
    meetingCancelled: 'Sesja odwołana',
    meetingCancelledByMe: 'Sesja odwołana przeze mnie',
    onePartyCancelled: 'Jedna strona odwołała',
    didNotHappen: 'Nie odbyło się',
    holiday: 'Urlop/Święto',
    outsideWorkingHours: 'Poza godzinami pracy'
  },
  service: 'Usługa:',
  meeting: 'Spotkanie:',
  services: 'Usługi',
  addNewService: 'Dodaj nową usługę',
  selectServices: 'Wybierz usługi',
  payment: 'Płatność',
  paymentDate: 'Data płatności',
  attachmentDeleted: 'Załącznik do spotkania został usunięty',
  payments: {
    paid: 'Opłacone',
    paymentNotRequired: 'Płatność niewymagana',
    awaitingPayment: 'Do opłacenia',
    unpaid: 'Nieopłacone',
    refunded: 'Płatność zwrócona',
    unknown: 'Nieznane',
    przelewy24: 'Przelewy24'
  },
  paymentMethod: 'Metoda płatności',
  paymentMethods: {
    cash: 'Gotówka',
    card: 'Karta',
    creditCard: 'Karta',
    bankTransfer: 'Przelew',
    blik: 'BLIK',
    transfer: '@:sessions.paymentMethods.bankTransfer',
    other: 'Inne',
    przelewy24: 'Przelewy24'
  },
  meetingNote: 'Notatki ze spotkania',
  attachments: 'Załączniki',
  attachmentsInTheFuture: 'Dodawanie załączników już wkrótce!',
  placeholders: {
    presence: 'Wybierz obecność',
    service: 'Wybierz usługę',
    payment: 'Wybierz płatność',
    prepaidMeetings: 'Spotkania przedpłacone',
    paymentMethod: 'Wybierz metodę płatności'
  },
  tooltips: {
    prepaidMeetings: 'Spotkania przedpłacone już wkrótce!',
    saveService: 'Wybierz przynajmniej jedną usługę'
  },
  cards: {
    contactDetails: 'Dane kontaktowe',
    upcomingMeetings: 'Nadchodzące spotkania',
    history: 'Historia',
    attachments: 'Załączniki',
    invoices: 'Faktury',
    gdpr: 'RODO',
    notifications: 'Powiadomienia'
  },
  tabs: {
    meetingDetails: 'Szczegóły spotkania'
  },
  notesOnlyMainTherapist:
    'Notatki sesji widoczne wyłącznie dla terapeuty prowadzącego',
  noAccess: 'Przykro nam, nie masz uprawnień do oglądania tych danych',
  pdfFields: {
    therapistName: 'Terapeuta: ',
    practiceName: 'Gabinet: ',
    patient: 'Pacjent: ',
    meetingType: 'Typ spotkania: ',
    meetingDateAndTime: 'Data i godzina spotkania: ',
    meetingNotes: 'Notatka:',
    meetingSecretNotes: 'Prywatna notatka:  ',
    meetingNoteTitle: 'Notatka ze spotkania'
  },
  print: {
    note: 'Wydrukuj notatkę dla pacjenta',
    all: 'Wydrukuj notatkę wraz z notatką prywatną'
  }
}
