module.exports = {
  isChosen: 'Wybrano',
  areYouSure: 'Czy na pewno?',

  table: {
    service: 'Usługa',
    dateAndHour: 'Data i godzina',
    patient: 'Pacjent',
    phone: 'Telefon',
    email: 'E-mail',
    therapist: 'Terapeuta',
    requestDate: 'Data prośby',
    result: 'Wynik',
    empty: 'Nie znaleziono rezerwacji',
    actions: 'Akcje'
  },

  actions: {
    delete: 'Usuń',
    execute: 'Wykonaj'
  },

  options: {
    confirm: 'Zaakceptuj spotkanie',
    reject: 'Odrzuć spotkanie',
    delete: 'Usuń spotkanie'
  },

  tooltips: {
    clickToOpenOptions: 'Kliknij aby otworzyć opcje'
  },

  filters: {
    therapists: 'Terapeuci',
    services: 'Usługi',
    patients: 'Pacjenci'
  },

  confirmation: {
    rejection: 'Jesteś pewny, że chcesz odrzucić rezerwację?',
    deleting: 'Jesteś pewny, że chcesz usunąć rezerwację?'
  }
}
